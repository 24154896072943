<template>
  <div class="cardBox">
    <div class="infoBox">
      <img :src="info.img" alt="" />
      <div>
        <div class="title">{{ info.title }}</div>
        <div class="desc">{{ info.desc }}</div>
      </div>
    </div>
    <el-button type="primary" size="small" @click="$emit('clickFn')">{{ info.btnTxt }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          img: '',
          title: '',
          desc: '',
          btnTxt: ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cardBox {
  width: 360px;
  height: 104px;
  background: #ffffff;
  border: 1px solid #f2f3f5;
  // box-shadow: 0px 4px 16px rgba(23, 58, 133, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 14px;
  margin-right: 20px;
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .infoBox {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
    .desc {
      margin-top: 10px;
      color: #b2b2b2;
    }
  }
}
</style>
